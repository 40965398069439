'use client'

import { useAuthState } from 'react-firebase-hooks/auth'

import { CalendarIcon, UsersIcon } from '@/components'
import { Navbar } from '@/components/layout/Navbar'
import { ROUTES } from '@/lib/constants/routes'
import { createFirebaseServices } from '@/lib/firebase/client'

export function NavBarWrapper({ lang }: { lang: Language }) {
  const { auth } = createFirebaseServices()
  const [user, loading, error] = useAuthState(auth)

  if (loading) return

  if (error) throw error

  const tenantId = user?.tenantId ?? ''

  return (
    <Navbar
      lang={lang}
      menus={[
        {
          id: 'desired-shift',
          title: 'シフト管理',
          icon: <CalendarIcon fontSize={16} />,
          path: `${ROUTES.SHIFT_APP}`,
        },
        {
          id: 'account-management',
          title: 'アカウント管理',
          icon: <UsersIcon fontSize={16} />,
          subMenus: [
            {
              id: 'tenant-info',
              title: 'テナント情報',
              path: `${ROUTES.TENANTS}/${tenantId}`,
            },
            {
              id: 'accounts',
              title: 'アカウント一覧',
              path: ROUTES.ACCOUNTS,
            },
          ],
        },
      ]}
    />
  )
}
