'use client'

import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useContext,
  useState,
} from 'react'
import { type MultiFactorError } from 'firebase/auth'

interface AuthContext {
  multiFactorError: MultiFactorError | null
  otpFromAuthenticator: string
  email: string
  password: string
}

export const AuthContext = createContext<AuthContext>({
  multiFactorError: null,
  otpFromAuthenticator: '',
  email: '',
  password: '',
})

export const SetAuthContext = createContext<Dispatch<SetStateAction<AuthContext>>>(() => {})

export function AuthProvider({ children }: PropsWithChildren) {
  const [value, setValue] = useState<AuthContext>({
    multiFactorError: null,
    otpFromAuthenticator: '',
    email: '',
    password: '',
  })
  return (
    <AuthContext.Provider value={value}>
      <SetAuthContext.Provider value={setValue}>{children}</SetAuthContext.Provider>
    </AuthContext.Provider>
  )
}

export const useAuthContextValue = () => useContext(AuthContext)
export const useSetAuthContextValue = () => useContext(SetAuthContext)
