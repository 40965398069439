'use client'

import {
  type CSSProperties,
  type PropsWithChildren,
  type ReactNode,
  useMemo,
  useState,
} from 'react'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@radix-ui/react-collapsible'
import { ChevronDownIcon, ChevronRightIcon } from 'lucide-react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'

import { useClientTranslation } from '@/app/i18n/client'
import { defaultLanguage, type Language } from '@/app/i18n/config'
import { cn } from '@/components'

export interface NavbarMenu {
  id: string
  title: string
  path?: string
  icon?: ReactNode
  subMenus?: NavbarMenu[]
}

interface NavbarProps {
  menus: Array<NavbarMenu>
  footerMenus?: Array<NavbarMenu>
  lang: Language
}

type NavbarItemProps = Partial<NavbarMenu> & {
  level: number
  activeMenuId?: string
  lang?: Language
}

const flattenMenus = (menus: Array<NavbarMenu>): Array<NavbarMenu> => {
  const result = []
  for (const menu of menus) {
    result.push(menu)
    if (menu.subMenus) {
      result.push(...flattenMenus(menu.subMenus))
    }
  }
  return result
}

function Navbar({ menus = [], footerMenus, lang }: NavbarProps) {
  const pathname = usePathname()
  const { locale } = useClientTranslation()

  const activeMenuId = useMemo(() => {
    const path = pathname.replace(`/${locale}`, '')
    const flatMenus = flattenMenus(menus)
    const activeMenu = flatMenus.find((menu) => menu.path === path)

    return activeMenu?.id
  }, [pathname, locale, menus])

  return (
    <aside className="fixed left-0 top-0 z-30 flex h-screen w-[var(--w-navbar)] flex-col bg-whitesmoke pt-[var(--h-navheader)] text-left text-lg text-darkslategray">
      <MainNavbar activeMenuId={activeMenuId} lang={lang} menus={menus} />
      {footerMenus ? <FooterNavbar activeMenuId={activeMenuId} menus={footerMenus} /> : null}
    </aside>
  )
}

function MainNavbar({
  menus,
  activeMenuId,
  lang,
}: {
  menus: Array<NavbarMenu>
  activeMenuId?: string
  lang: Language
}) {
  return (
    <nav className="flex-1 overflow-auto">
      {menus.map((menu) => {
        if (menu.subMenus && menu.subMenus.length > 0) {
          return (
            <NavbarCollapseItem key={menu.id} {...menu} activeMenuId={activeMenuId} level={1} />
          )
        }
        return (
          <NavbarItem key={menu.id} {...menu} activeMenuId={activeMenuId} lang={lang} level={1} />
        )
      })}
    </nav>
  )
}

function NavbarCollapseItem(props: NavbarItemProps) {
  const { id, activeMenuId, subMenus = [], level } = props
  const [isExpanded, setIsExpanded] = useState(true)
  const isActive = useMemo(() => activeMenuId === id, [activeMenuId, id])

  return (
    <Collapsible onOpenChange={setIsExpanded} open={isExpanded}>
      <div className="flex h-14 w-full select-none items-center">
        <NavbarItem {...props} />
        <CollapsibleTrigger
          className={cn(
            'h-full shrink-0 w-14 flex items-center justify-center',
            isActive && 'bg-[#a5a5a5] text-white'
          )}
        >
          <span className="box-content flex size-4 shrink-0 items-center justify-center text-base">
            {isExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </span>
        </CollapsibleTrigger>
      </div>
      <CollapsibleContent>
        {subMenus.map((menu) => {
          const Item = menu.subMenus ? NavbarCollapseItem : NavbarItem
          return <Item key={menu.id} {...menu} activeMenuId={activeMenuId} level={level + 1} />
        })}
      </CollapsibleContent>
    </Collapsible>
  )
}

function NavbarItem(props: NavbarItemProps) {
  const { id, activeMenuId, title, path, icon, level, lang } = props
  const isActive = useMemo(() => activeMenuId === id, [id, activeMenuId])

  return (
    <NavLink href={path} isActive={isActive} lang={lang} level={level}>
      {icon ? (
        <span className="flex size-4 shrink-0 items-center justify-center">{icon}</span>
      ) : null}
      <span className={cn('flex-1 font-normal', level < 3 ? 'text-lg' : 'text-base')}>{title}</span>
    </NavLink>
  )
}

function NavLink({
  href,
  isActive,
  children,
  level = 1,
  lang,
}: PropsWithChildren<{ href?: string; level?: number; isActive?: boolean; lang?: Language }>) {
  const navLinkClasses = cn(
    'flex h-14 w-full items-center gap-2.5 select-none pl-[var(--pl-navlink)]',
    isActive ? 'bg-brand text-white [&>*]:font-bold' : 'text-darkslategray'
  )
  const navLinkStyle = useMemo(() => ({ '--pl-navlink': `${level * 10}px` }), [level])
  const url = href && /^https?:\/\//.test(href) ? href : `/${lang ?? defaultLanguage + href}`

  return href ? (
    <Link className={navLinkClasses} href={url} prefetch style={navLinkStyle as CSSProperties}>
      {children}
    </Link>
  ) : (
    <div className={navLinkClasses} style={navLinkStyle as CSSProperties}>
      {children}
    </div>
  )
}

function FooterNavbar({
  menus = [],
  activeMenuId,
}: {
  menus?: Array<NavbarMenu>
  activeMenuId?: string
}) {
  return (
    <nav className="shrink-0 border-t border-darkgray pb-3">
      {menus.map((menu) => {
        if (menu.subMenus && menu.subMenus.length > 0) {
          return (
            <NavbarCollapseItem key={menu.id} {...menu} activeMenuId={activeMenuId} level={1} />
          )
        }
        return <NavbarItem key={menu.id} {...menu} activeMenuId={activeMenuId} level={1} />
      })}
    </nav>
  )
}

export { Navbar }
