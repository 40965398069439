'use client'

import { type PropsWithChildren, useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { multiFactor } from 'firebase/auth'
import { useRouter } from 'next/navigation'

import { useRevokeRefreshToken } from '@/components/hooks'
import { ROUTES } from '@/lib/constants/routes'
import { createFirebaseServices } from '@/lib/firebase/client'

import { useSetAuthContextValue } from '../../(pre-authenticated)/_components/AuthContextProvider'

export function PrivateWrapper({
  children,
  fallbackUrl,
}: PropsWithChildren<{ fallbackUrl?: string }>) {
  const router = useRouter()
  const { auth } = createFirebaseServices()
  const [user, loading, error] = useAuthState(auth)

  const setAuthValue = useSetAuthContextValue()

  const { signOut } = useRevokeRefreshToken(auth)

  useEffect(() => {
    const initialState = {
      multiFactorError: null,
      otpFromAuthenticator: '',
      email: '',
      password: '',
    }
    setAuthValue(initialState)
  }, [setAuthValue])

  useEffect(() => {
    if (loading) return

    if (error) throw error

    if (!user) {
      router.replace(fallbackUrl || ROUTES.ROOT)
    } else {
      if (!multiFactor(user).enrolledFactors?.length) {
        signOut()
        router.replace(ROUTES.ROOT)
        return
      }
    }
  }, [fallbackUrl, router, error, user, loading, auth, signOut])

  return children
}
